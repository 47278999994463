<template>
  <div class="container-fluid">
    <div class="row py-3">
      <div class="col-12">
        <div v-html="rules"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      rules: ''
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console'
    ])
  },
  mounted() {
    this.$store.dispatch('SET_MENU_PRIMARY_TOURNAMENT', 'rules')
    this.fetchData()
  },
  methods: {
    fetchData() {
      const lang = this.$route.params.lang
      const console = this.$route.params.console
      const slug = this.$route.params.tournamentSlug
      this.path = `${lang}/console/${console}/tournament/${slug}/rules`
      this.$axios.get(this.path).then((response) => {
        this.rules = response.data.data.content
      })
    }
  }
}
</script>
